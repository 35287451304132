// cherrypick functions
import { toggleAccordions } from "./components/accordion"
import "./components/nav"
import "./components/filters"
import "./components/code-block"
// import { trapFocus } from "./helpers/trapFocus"

/* LOAD SCRIPTS */
document.addEventListener("DOMContentLoaded", () => {
    // ACCORDION
    const accordionButtons = document.querySelectorAll(".js-accordion-btn")
    if (accordionButtons) {
        toggleAccordions(accordionButtons)
    }

    /* begin begin Back to Top button  */
    const goTopBtn = document.querySelector(".back-to-top")
    if (goTopBtn) {
        ;(function () {
            "use strict"

            function trackScroll() {
                let scrolled = window.pageYOffset
                const coords = document.documentElement.clientHeight

                if (scrolled > coords) {
                    goTopBtn.classList.add("back-to-top--visible")
                }
                if (scrolled < coords) {
                    goTopBtn.classList.remove("back-to-top--visible")
                }
            }

            function backToTop() {
                if (window.pageYOffset > 0) {
                    document.body.scrollTop = 0 // For Safari
                    document.documentElement.scrollTop = 0 // For Chrome, Firefox, IE and Opera
                }
            }

            window.addEventListener("scroll", trackScroll)
            goTopBtn.addEventListener("click", backToTop)
        })()
    }

    /* end begin Back to Top button  */
})
