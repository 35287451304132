import { slideToggle, toggleAria } from "../helpers/toggles"

export function toggleAccordions(buttons) {
    const accordionHead = Array.from(buttons)

    accordionHead.forEach((link) => {
        link.addEventListener("click", (e) => {
            e.preventDefault()
            link.classList.toggle("nx-accordion__head--active")
            slideToggle(link.nextElementSibling, 200)
            toggleAria(link, "aria-expanded")
        })
    })
}
