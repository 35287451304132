const techData = document.querySelector(".tech-data")

//if (techData) {
//    techData.innerHTML = JSON.stringify(data, undefined, 2)
//}

const copyButton = document.querySelector(".copy-json")
if (copyButton) {
    let text = techData.innerHTML
    const copyContent = async () => {
        try {
            await navigator.clipboard.writeText(text)
            console.log("Content copied to clipboard")
            copyButton.classList.add("success")
            window.setTimeout(function () {
                copyButton.classList.remove("success")
            }, 3000)
        } catch (err) {
            console.error("Failed to copy: ")
        }
    }
    copyButton.addEventListener("click", copyContent)
}
