// import { trapFocus } from "../helpers/trapFocus"

const filtersButton = document.querySelector(".filters-button")
const closeFiltersButton = document.querySelector(".close-filters-button")
const filters = document.querySelector(".filters")
const mdcTagFilter = document.querySelector(".js-mdc-tag-filter")
const body = document.body
const html = document.documentElement

if (filters) {
    filtersButton.addEventListener("click", function () {
        if (this.getAttribute("aria-expanded") === "false") {
            const scrollY = document.documentElement.style.getPropertyValue("--scroll-y")
            this.setAttribute("aria-expanded", "true")
            html.classList.add("filters-open")
            body.style.top = `-${scrollY}`
            // trapFocus(filters[0], filtersButton[0])
        } else {
            const scrollY = body.style.top
            this.setAttribute("aria-expanded", "false")
            html.classList.remove("filters-open")
            body.style.top = ""
            window.scrollTo(0, parseInt(scrollY || "0") * -1)
        }
    })

    closeFiltersButton.addEventListener("click", function () {
        html.classList.remove("filters-open")
        filtersButton.setAttribute("aria-expanded", "false")
    })
}

const filtersCheckboxes = document.querySelectorAll(".js-filter-checkbox")
if (filtersCheckboxes) {
    filtersCheckboxes.forEach((el) => {
        el.addEventListener("input", (e) => {
            e.preventDefault()

            const value = el.value
            const matchingElements = document.querySelectorAll(
                `input[id="org-${value}"], input[id="tag-${value}"]`
            )

            matchingElements.forEach((matchEl) => {
                if (matchEl !== el) {
                    matchEl.checked = false
                }
            })

            const parentForm = el.closest("form")
            if (parentForm) {
                parentForm.submit()
            }
        })
    })
}

const filterOrder = document.querySelector(".js-filter-order")
if (filterOrder) {
    filterOrder.addEventListener("input", (e) => {
        e.preventDefault()
        const parentForm = filterOrder.closest("form")
        if (parentForm) {
            parentForm.submit()
        }
    })
}

const clearAll = document.querySelector(".js-clear-filters")
if (clearAll) {
    clearAll.addEventListener("click", (e) => {
        e.preventDefault()
        filtersCheckboxes.forEach((el) => {
            el.checked = false
        })

        const parentForm = filterOrder.closest("form")
        if (parentForm) {
            parentForm.submit()
        }
    })
}

if (mdcTagFilter) {
    const mdcFilterParent = mdcTagFilter.closest(".nx-accordion__content")
    const mdcTags = mdcFilterParent.querySelectorAll('input[type="checkbox"]')
    mdcTagFilter.addEventListener("input", () => {
        const query = mdcTagFilter.value
        let filteredMdcTags = [...mdcTags]

        if (query !== "") {
            filteredMdcTags.forEach((tag) => {
                const parent = tag.parentElement
                if (!tag.value.toLowerCase().includes(query.toLowerCase())) {
                    parent.style.display = "none"
                } else {
                    parent.removeAttribute("style")
                }
            })
        } else {
            filteredMdcTags.forEach((tag) => {
                const parent = tag.parentElement
                parent.removeAttribute("style")
            })
        }
    })
}
